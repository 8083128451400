// authConfig.js
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AD_B2C_CLIENT_ID,
    // Replace with your client ID
    authority: `https://${process.env.VUE_APP_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.VUE_APP_AD_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.VUE_APP_AD_B2C_USER_FLOW}`,
    knownAuthorities: [
      `${process.env.VUE_APP_AD_B2C_TENANT_NAME}.b2clogin.com`,
    ],
    redirectUri: `${process.env.VUE_APP_DOMAIN_URL}/auth-response`, // Or your frontend callback URL
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    `https://${process.env.VUE_APP_AD_B2C_TENANT_NAME}.onmicrosoft.com/api/read`,
  ],
};
