<template>
  <div class="footer-wrapper">
    <!-- <footer class="footer">
      Copyright © 2011-{{ new Date().getFullYear() }}
      {{ this.$appInfo.title }} Inc. <br />All trademarks or registered
      trademarks are property of their respective owners.
    </footer> -->
  </div>
</template>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer-wrapper {
  margin-top: 50px;
  background-image: url("../assets/images/checkered-background.png");
  background-size: cover; /* Ensure the image covers the footer */
  background-position: center; /* Adjust the horizontal position */
  width: 100%;
  height: 70px;
  border-top: 2px solid #435469;
}

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
}
</style>
